











































import {computed, defineComponent, onMounted, onUnmounted, PropType, ref} from "@nuxtjs/composition-api";
import { SfButton } from "@storefront-ui/vue";
import {ContentConfigsInterface} from "../../../types";
import useMediaQuery from "../../../composables/useMediaQuery/useMediaQuery";
import resourceUrl from "../../../helpers/url/makeUrl";

export default defineComponent({
  name: 'BannerFragment',
  components: {SfButton},
  props: {
    data: {
      type: Object as PropType<ContentConfigsInterface>,
      required: true,
    },
    overlayClass: {
      type: String
    }
  },

  setup(props) {
    const {
      appearance = 'poster',
      backgroundAttachment,
      backgroundColor,
      backgroundImage,
      backgroundPosition,
      backgroundRepeat = 'repeat',
      backgroundSize,
      backgroundType,
      border,
      borderColor,
      borderRadius,
      borderWidth,
      buttonText,
      buttonType,
      content,
      desktopImage,
      getParallax = () => {},
      mediaQueries,
      minHeight,
      mobileImage,
      overlayColor,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      showButton,
      showOverlay,
      textAlign,
      videoFallbackSrc,
      videoLazyLoading,
      videoLoop,
      videoOverlayColor,
      videoPlayOnlyVisible,
      videoSrc
    }: ContentConfigsInterface = props.data;

    const backgroundElement = ref(null);
    const viewportElement = ref(null);
    const bgImageStyle = ref<string|null>(null);
    let observerInstance = null;
    let jarallaxInstance = null;

    // Todo: needs to test is the received data correct. I dont know how fill mediaQueries variable
    const mediaQueryStyles = useMediaQuery({mediaQueries: mediaQueries});

    const { matchMedia } = globalThis;

    const image = computed(() => {
      if (mobileImage && matchMedia && matchMedia('(max-width: 768px)').matches) {
        return mobileImage;
      }
      return desktopImage;
    });

    const wrapperStyles: ContentConfigsInterface = {
      backgroundColor,
      border,
      borderColor,
      borderWidth,
      borderRadius,
      textAlign
    };

    const overlayStyles: ContentConfigsInterface = {
      backgroundColor: showOverlay !== 'never' ? overlayColor : null
    };

    const contentStyles: ContentConfigsInterface = {};

    const videoOverlayStyles: ContentConfigsInterface = {
      backgroundColor: videoOverlayColor
    };

    const isShowButton = showButton !== 'never';
    const isButtonHover = showButton === 'hover';

    const typeToPriorityMapping = {
      primary: 'primary-button',
      secondary: 'secondary-button',
      link: 'link-button'
    };

    const isBackgroundTypeVideo = backgroundType === 'video';

    if (appearance === 'poster') {
      overlayStyles.minHeight = minHeight;
      overlayStyles.paddingTop = paddingTop;
      overlayStyles.paddingRight = paddingRight;
      overlayStyles.paddingBottom = paddingBottom;
      overlayStyles.paddingLeft = paddingLeft;
      contentStyles.width = '100%';
    } else {
      wrapperStyles.minHeight = minHeight;
      wrapperStyles.paddingTop = paddingTop;
      wrapperStyles.paddingRight = paddingRight;
      wrapperStyles.paddingBottom = paddingBottom;
      wrapperStyles.paddingLeft = paddingLeft;
    }

    if(image.value) {
      wrapperStyles.backgroundSize = backgroundSize;
      wrapperStyles.backgroundPosition = backgroundPosition;
      wrapperStyles.backgroundAttachment = backgroundAttachment;
      wrapperStyles.backgroundRepeat = backgroundRepeat;
    }

    const setBgImage = () => {
      const resourceImage = resourceUrl(image.value, {
        type: 'image-wysiwyg',
        quality: 85
      });

      const backgroundImage = document.createElement('img');
      backgroundImage.src = resourceImage;
      bgImageStyle.value = resourceImage;
    };

    const loadImageIfVisible = () => {
      if (typeof IntersectionObserver === "undefined") {
        setBgImage();
        return;
      }

      const htmlElement = backgroundElement.value;

      const onIntersection = entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          observerInstance.unobserve(htmlElement);

          setBgImage();
        }
      };
      observerInstance = new IntersectionObserver(onIntersection);
      observerInstance.observe(htmlElement);
    }

    const addVideoBackground = () => {
      const parallaxElement = backgroundElement.value;
      let jarallax;
      let jarallaxVideo;

      ({jarallax: jarallaxInstance} = require('jarallax'));
      ({ jarallaxVideo } = require('jarallax'));
      jarallaxVideo();
      jarallaxInstance(parallaxElement, {
        speed: 1,
        imgSrc: videoFallbackSrc
          ? resourceUrl(videoFallbackSrc, {
            type: 'image-wysiwyg',
            quality: 85
          })
          : null,
        elementInViewport: viewportElement.value,
        videoSrc,
        videoLoop,
        videoPlayOnlyVisible,
        videoLazyLoading
      });

      parallaxElement.jarallax.video &&
      parallaxElement.jarallax.video.on('started', () => {
        const self = parallaxElement.jarallax;

        // show video
        if (self.$video) {
          self.$video.style.visibility = 'visible';
        }
      });
    }

    onMounted(() => {
      if (image.value && backgroundElement.value) {
        loadImageIfVisible()
      }

      if (backgroundType === 'video') {
        addVideoBackground();
      }
    });

    onUnmounted(() => {
      if (observerInstance) {
        observerInstance.unobserve(backgroundElement.value);
      }

      if (jarallaxInstance) {
        jarallaxInstance(backgroundElement.value, 'destroy')
      }
    });

    return {
      appearance,
      backgroundElement,
      bgImageStyle,
      buttonType,
      buttonText,
      content,
      contentStyles,
      isBackgroundTypeVideo,
      isButtonHover,
      isShowButton,
      mediaQueryStyles,
      overlayStyles,
      typeToPriorityMapping,
      videoOverlayColor,
      videoOverlayStyles,
      viewportElement,
      wrapperStyles,
    }
  },
  watch: {
    bgImageStyle() {
      if(this.bgImageStyle) {
        this.wrapperStyles.backgroundImage = `url(${this.bgImageStyle})`;
      }
    },

  }
});
