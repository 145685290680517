






























import {computed, defineComponent, PropType, ref} from "@nuxtjs/composition-api";
import {ContentConfigsInterface} from "../../../types";
import BannerFragment from "./BannerFragment.vue";
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';

export default defineComponent({
  name: 'Banner',
  components: {BannerFragment, LoadWhenVisible},
  props: {
    data: {
      type: Object as PropType<ContentConfigsInterface>,
      required: true,
    },
  },
  setup(props) {
    const {
      appearance = 'poster',
      cssClasses = [],
      link,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      openInNewTab = false,
      showOverlay
    }: ContentConfigsInterface = props.data;

    const hasLinkWrapper = typeof link === 'string';

    const hovered = ref<boolean>(false);

    const rootStyles: ContentConfigsInterface = {
      marginTop,
      marginRight,
      marginBottom,
      marginLeft
    };

    const appearanceOverlayClasses = {
      poster: 'bannerPosterOverlay',
      'collage-left': 'collageLeftOverlay',
      'collage-centered': 'collageCenteredOverlay',
      'collage-right': 'collageRightOverlay'
    };

    const appearanceOverlayHoverClasses = {
      poster: 'bannerPosterOverlayHover',
      'collage-left': 'collageLeftOverlayHover',
      'collage-centered': 'collageCenteredOverlayHover',
      'collage-right': 'collageRightOverlayHover'
    };

    const overlayClass = computed(() => (
      showOverlay === 'hover' && !hovered.value
        ? appearanceOverlayHoverClasses[appearance]
        : appearanceOverlayClasses[appearance]
    ));

    return {
      cssClasses,
      hasLinkWrapper,
      hovered,
      link,
      openInNewTab,
      overlayClass,
      rootStyles,
    }
  },
  methods: {
    toggleHover() {
      this.hovered = !this.hovered;
    },
  },
});
